import { BrowserRouter, Routes, Route } from 'react-router-dom';
import PrivateRoute from '../../features/auth/containers/PrivateRoute';
import LoginPage from '../../features/auth/pages/LoginPage';
import ProfilePage from '../../features/auth/pages/ProfilePage';
import HomePage from '../../features/HomePage';
import ListPartnerPage from '../../features/partner/pages/ListPartnerPage';
import CreatePartnerPage from '../../features/partner/pages/CreatePartnerPage';
import EditPartnerPage from '../../features/partner/pages/EditPartnerPage';
import CreateStatPage from '../../features/stat/pages/CreateStatPage';
import ListContactUsPage from '../../features/contactUs/pages/ListContactUsPage';

const RouteManager = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route
          path="/"
          element={
            <PrivateRoute>
              <HomePage />
            </PrivateRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <PrivateRoute>
              <ProfilePage />
            </PrivateRoute>
          }
        />

        <Route
          path="/partners"
          element={
            <PrivateRoute>
              <ListPartnerPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/partners/create"
          element={
            <PrivateRoute>
              <CreatePartnerPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/partners/edit/:id"
          element={
            <PrivateRoute>
              <EditPartnerPage />
            </PrivateRoute>
          }
        />

        <Route
          path="/contact"
          element={
            <PrivateRoute>
              <ListContactUsPage />
            </PrivateRoute>
          }
        />

        <Route
          path="/stats"
          element={
            <PrivateRoute>
              <CreateStatPage />
            </PrivateRoute>
          }
        />

        <Route path="*" element={<>Not Found page</>} />
      </Routes>
    </BrowserRouter>
  );
};

export default RouteManager;
