import { Box } from '@mui/material';
import { useState } from 'react';
import useFetchApiData from '../../../core/hooks/useFetchApiData';
import Layout from '../../../core/ui/layout/Layout';
import TableContainer from '../../../core/ui/table/TableContainer';
import { TableHeader } from '../../../core/ui/table/tableTypes';
import { Paginated } from '../../../core/utils/types';
import { parseQuery } from '../../../core/utils/utility';
import { Partner } from '../partner';
import { useSendApiData } from '../../../core/hooks/useSendApiData';
import { toastError, toastMessage } from '../../../core/utils/ui/alert';
import { getMediaUrl } from '../../../core/utils/image';
import constants from '../../../core/utils/constants';

const tableHeaders: TableHeader[] = [
  { field: 'name', label: 'Name', align: 'left' },
  {
    field: 'media',
    label: 'Image',
    align: 'left',
    renderItem: (item) => (
      <img src={getMediaUrl((item || [])[0])} width="50px" alt="logo" />
    ),
  },
];
const PartnerList = () => {
  const [data, setData] = useState<Record<string, any>[]>([]);
  const { fetchData } = useFetchApiData();
  const { callApi } = useSendApiData();

  const handleFetchData = async (query: any, cb: (c: number) => void) => {
    await fetchData(`partners${parseQuery(query)}`, {
      onSuccess: ({ total, data: apiData }: Paginated<Partner>) => {
        setData(apiData);
        cb(total);
      },
    });
  };

  const handleDelete = async (id: number | string, showMessage = true) => {
    let success = false;

    await callApi({
      endpoint: `${constants.authUrl}/partners/${id}`,
      data: {},
      method: 'delete',
      onSuccess: async () => {
        if (showMessage) toastMessage('Item Removed');
        success = true;
      },
      onError: () => {
        if (showMessage)
          toastError('Something went wrong while deleting. Try again');
      },
    });

    return success;
  };

  return (
    <>
      <Layout>
        <Box sx={{ p: 2, maxWidth: 900 }}>
          <TableContainer
            modelToken="partner"
            tableHeaders={tableHeaders}
            data={data}
            modelLabel="Partners"
            actions={{
              onFetchData: handleFetchData,
              onDelete: handleDelete,
            }}
            routes={{
              edit: '/partners/edit',
              create: '/partners/create',
              delete: '/partners',
              view: '/partners',
            }}
            settings={{
              canCreate: true,
              canEdit: true,
              canDelete: true,
              canViewItem: false,
            }}
          />
        </Box>
      </Layout>
    </>
  );
};

export default PartnerList;
