import { FormikHelpers, useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { PartnerCreate } from '../partner';
import {
  Grid,
  TextField,
  Box,
  FormHelperText,
  Paper,
  Button,
} from '@mui/material';

type PartnerProps = {
  onSubmit: (
    value: PartnerCreate,
    helpers: FormikHelpers<PartnerCreate>
  ) => Promise<boolean>;
  submitting: boolean;
};

const CreatePartnerForm = ({ onSubmit, submitting }: PartnerProps) => {
  const navigate = useNavigate();

  const handleSubmit = async (
    value: PartnerCreate,
    helpers: FormikHelpers<PartnerCreate>
  ) => {
    const success = await onSubmit(value, helpers);
    if (success) {
      navigate('/', { replace: true });
      navigate('/partners/create', { replace: true });
    }
  };

  const initialValues: PartnerCreate = { name: '', image: null };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
  });

  // console.log('Edit Form', initialValues);

  return (
    <>
      <Box sx={{ flexGrow: 1, maxWidth: 400 }}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={1}>
            <Paper sx={{ p: 2, pb: 3 }}>
              <Grid
                container
                justifyContent="space-between"
                spacing={1}
                sx={{ mt: 2 }}
              >
                <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                  <TextField
                    fullWidth
                    name="name"
                    size="small"
                    type="text"
                    variant="outlined"
                    label="Name"
                    placeholder=""
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={Boolean(formik.errors.name)}
                    helperText={formik.errors.name}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="space-between"
                spacing={1}
                sx={{ mt: 2 }}
              >
                <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                  <Grid item xs={12}>
                    Image
                  </Grid>
                  <Box>
                    <input
                      type="file"
                      onChange={({ target: { files } }) => {
                        formik.setFieldValue(
                          'image',
                          files && files.length ? files[0] : null
                        );
                      }}
                      name="image"
                    />
                  </Box>
                  {formik.touched.image && formik.errors.image && (
                    <FormHelperText error>{formik.errors.image}</FormHelperText>
                  )}
                </Grid>
              </Grid>

              <Grid container sx={{ mt: 2 }}>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={submitting}
                  size="small"
                  fullWidth
                >
                  {submitting ? 'Saving' : 'Save'}
                </Button>
              </Grid>
            </Paper>
          </Grid>
        </form>
      </Box>
    </>
  );
};

export default CreatePartnerForm;
