import { FormikHelpers, useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { StatCreate, Stat } from '../stat';
import { Grid, TextField, Paper, Box, Button } from '@mui/material';

type StatProps = {
  onSubmit: (
    value: StatCreate,
    helpers: FormikHelpers<StatCreate>
  ) => Promise<boolean>;
  submitting: boolean;
  stat: Stat | null;
};

const CreateStatForm = ({ onSubmit, submitting, stat }: StatProps) => {
  const navigate = useNavigate();

  const handleSubmit = async (
    value: StatCreate,
    helpers: FormikHelpers<StatCreate>
  ) => {
    const success = await onSubmit(value, helpers);
    if (success) {
      navigate('/', { replace: true });
      navigate('/stats', { replace: true });
    }
  };

  const initialValues: StatCreate = !stat
    ? { partners: '', consumers: '', orders: '', categories: '', date: '' }
    : {
        partners: stat.partners || '',
        consumers: stat.consumers || '',
        orders: stat.orders || '',
        categories: stat.categories || '',
        date: stat.date || '',
      };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
  });

  // console.log('Edit Form', initialValues);

  return (
    <>
      <Box sx={{ flexGrow: 1, maxWidth: 500 }}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={1}>
            <Paper sx={{ p: 2, pb: 3 }}>
              <Grid container justifyContent="space-between" spacing={1}>
                <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                  <TextField
                    fullWidth
                    name="partners"
                    size="small"
                    variant="outlined"
                    label="Partners"
                    placeholder=""
                    value={formik.values.partners}
                    onChange={formik.handleChange}
                    error={Boolean(formik.errors.partners)}
                    helperText={formik.errors.partners}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="space-between"
                spacing={1}
                sx={{ mt: 2 }}
              >
                <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                  <TextField
                    fullWidth
                    name="consumers"
                    size="small"
                    variant="outlined"
                    label="Consumers"
                    placeholder=""
                    value={formik.values.consumers}
                    onChange={formik.handleChange}
                    error={Boolean(formik.errors.consumers)}
                    helperText={formik.errors.consumers}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="space-between"
                spacing={1}
                sx={{ mt: 2 }}
              >
                <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                  <TextField
                    fullWidth
                    name="orders"
                    size="small"
                    variant="outlined"
                    label="Orders"
                    placeholder=""
                    value={formik.values.orders}
                    onChange={formik.handleChange}
                    error={Boolean(formik.errors.orders)}
                    helperText={formik.errors.orders}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="space-between"
                spacing={1}
                sx={{ mt: 2 }}
              >
                <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                  <TextField
                    fullWidth
                    name="categories"
                    size="small"
                    variant="outlined"
                    label="Categories"
                    placeholder=""
                    value={formik.values.categories}
                    onChange={formik.handleChange}
                    error={Boolean(formik.errors.categories)}
                    helperText={formik.errors.categories}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="space-between"
                spacing={1}
                sx={{ mt: 2 }}
              >
                <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                  <TextField
                    fullWidth
                    name="date"
                    size="small"
                    type="date"
                    variant="outlined"
                    placeholder="Date"
                    value={formik.values.date}
                    onChange={formik.handleChange}
                    error={Boolean(formik.errors.date)}
                    helperText={formik.errors.date}
                  />
                </Grid>
              </Grid>

              <Grid container sx={{ mt: 2 }}>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={submitting}
                  size="small"
                  fullWidth
                >
                  {submitting ? 'Saving' : 'Save'}
                </Button>
              </Grid>
            </Paper>
          </Grid>
        </form>
      </Box>
    </>
  );
};

export default CreateStatForm;
