import _ from 'lodash';
import { Grid, Typography } from '@mui/material';
import { FormikHelpers } from 'formik';
import Layout from '../../../core/ui/layout/Layout';
import { useSendApiData } from '../../../core/hooks/useSendApiData';
import { toastError, toastMessage } from '../../../core/utils/ui/alert';
import { parseValidationErrors } from '../../../core/utils/validation';
import { StatCreate, Stat } from '../stat';
import CreateStatForm from '../components/CreateStatForm';
import { parseFormQuery } from '../../../core/utils/utility';
import { useState, useEffect } from 'react';
import useFetchApiData from '../../../core/hooks/useFetchApiData';
import Loader from '../../../core/ui/utility/Loader';
import constants from '../../../core/utils/constants';

const CreateStatContainer = () => {
  const { callApi, loading: submitting } = useSendApiData();
  const { fetchData, loading } = useFetchApiData();
  const [stat, setStat] = useState<Stat | null>(null);

  const fetchStat = () =>
    fetchData(`stats`, {
      onSuccess: (data: Stat) => {
        if (!_.isEmpty(data)) {
          setStat(data);
        } else {
          setStat(null);
        }
      },
    });

  useEffect(() => {
    fetchStat();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (
    values: StatCreate,
    { setFieldError }: FormikHelpers<StatCreate>
  ) => {
    let success = false;

    const formData: any = parseFormQuery(values, []);

    await callApi({
      endpoint: `${constants.authUrl}/stats`,
      data: formData,
      headers: {
        'Content-Type': 'application/json',
      },
      onValidationError: (err) => parseValidationErrors(err, setFieldError),
      onError: toastError,
      onSuccess: async (_) => {
        toastMessage('Stat Created');
        success = true;
      },
    });

    return success;
  };

  return (
    <Layout>
      <Loader loading={loading}>
        <Grid sx={{ p: 4 }}>
          <Grid container sx={{ mb: 2, px: 1 }}>
            <Typography variant="h5">{stat ? 'Edit' : 'Add'} Stat</Typography>
          </Grid>
          <CreateStatForm
            stat={stat}
            onSubmit={handleSubmit}
            submitting={submitting}
          />
        </Grid>
      </Loader>
    </Layout>
  );
};

export default CreateStatContainer;
